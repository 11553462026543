<template>
  <ul :key="insiders" class="flex flex-wrap">
    <ticket-item
      v-for="(ins, idx) in insiders"
      :key="idx"
      :data="ins"
      :index="idx + 1"
    ></ticket-item>
  </ul>
</template>

<script setup>
import TicketItem from "@/components/TicketItem.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";
import tk from "@/assets/data/tickets.json";
import ins from "@/assets/data/insiders.json";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

// const insiders = computed(()=> archiveStore.getInsiders)
const tickets = computed(() => tk.reverse());

const insiders = computed(() =>
  ins
    .reverse()
    .filter((item) => item.ft === true)
    .slice(ins.length > 10 ? ins.length - 10 : 0)
);

const currentTickets = computed(() => archiveStore.getCurrentTickets);
</script>
