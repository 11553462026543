<template>
  <div class="home mb-12">
    <div
      class="hero h-[280px] pt-[192px] md:pt-[172px] lg:pt-[324px] lg:h-[480px] bg-darkblue/20"
    >
      <base-section class="w-full bg-darkblue/70 py-2 px-1 md:px-4 text-white">
        <base-card class="">
          <base-card-section
            class="flex justify-between items-center px-1 lg:px-4 lg:py-2"
          >
            <div
              class="flex flex-col justify-start text-base sm:text-md md:text-xl lg:text-[38px] font-bold md:leading-8 lg:leading-[44px] tracking-tight"
            >
              <p>VIP SERVICE</p>
              <p class="text-active">
                <span v-if="width > 270">ACTIVE</span> {{ activeVipDate }}
              </p>
              <p class="text-base sm:text-md md:text-lg lg:text-3xl">
                <span v-if="width > breakpoint.sm">AVAILABLE</span> UNTIL
                {{ activeVipTime }}
              </p>
            </div>
            <div
              class="flex flex-col lg:justify-start text-base sm:text-md md:text-xl lg:text-3xl font-bold tracking-tight text-center"
            >
              <p class="text-active">
                <span v-if="width > breakpoint.sm">UPCOMING</span> INSIDERS
              </p>
              <p
                v-for="(ins, idx) in currentInsidersLive.slice(0, 2)"
                :key="idx"
              >
                {{ ins.date }}
              </p>
            </div>
          </base-card-section>
        </base-card>
      </base-section>
    </div>

    <base-section class="p-2 lg:p-4">
      <div
        class="flex items-center lg:justify-between justify-center w-full pb-4 flex-wrap"
      >
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/PayPal.webp')"
        ></base-image>
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/skrill.webp')"
        ></base-image>
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/neteller.webp')"
        ></base-image>
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/bitcoin.webp')"
        ></base-image>
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/moneygram.webp')"
        ></base-image>
        <base-image
          fit="contain"
          class="lg:w-1/6 flex justify-center w-[77.5px] h-[28px] sm:w-[155px] sm:h-[56px]"
          :src="require('../assets/wu.webp')"
        ></base-image>
      </div>
    </base-section>

    <base-section class="px-2 py-8 lg:p-4">
      <base-card
        flat
        class="md:bg-white/30 flex p-4 items-center justify-center"
      >
        <div class="text-center">
          <h2 class="text-2xl md:text-4xl uppercase font-bold pb-2">
            DON'T MISS GREAT OFFERS
            <!-- UPCOMING INSIDERS:
            <span class="text-active px-2" v-for="(ins, idx) in currentInsiders"
              >{{ ins.date }}
            </span> -->
          </h2>
          <p class="font-bold text-xl md:text-2xl">
            Contact us on:
            <span class="font-bold text-darkblue">shark1x2@hotmail.com</span>
          </p>
        </div>
      </base-card>
    </base-section>

    <base-section class="px-4 pb-8 pt-4 lg:pt-16">
      <h2 class="text-3xl px-4 flex justify-center md:justify-start">
        SUBSCRIPTIONS
      </h2>
      <base-card
        class="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 lg:space-x-4 xl:space-x-16 space-x-2 py-4"
      >
        <subscription-card class="md:w-1/3">
          <template #star>
            <div class="py-2 flex justify-around">
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
            </div>
          </template>
          <template #offer>
            <ul
              class="w-full lg:px-4 px-4 md:px-2 font-normal text-xs lg:text-sm space-y-1"
            >
              <li class="flex items-center justify-between space-x-2">
                <p>
                  15 <span v-if="width > breakpoint.sm">VIP</span> PICKS -
                  <span class="font-bold">50€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="BSALV3MF8KEG8"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  30 <span v-if="width > breakpoint.sm">VIP</span> PICKS -
                  <span class="font-bold">80€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="GPBV3TP23SZA6"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  90 <span v-if="width > breakpoint.sm">VIP</span> PICKS -
                  <span class="font-bold">180€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="JMW3QN7XYVCHJ"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  150 <span v-if="width > breakpoint.sm">VIP</span> PICKS -
                  <span class="font-bold">250€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="CZEDYFV777CFE"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
            </ul>
          </template>

          <template #header> VIP PICKS </template>
          <template #stake> Stake 8.5/10 </template>
          <template #odds> Odds: Over 1.75 </template>
        </subscription-card>
        <subscription-card starred class="md:w-1/3">
          <template #star>
            <div class="py-2 flex justify-around">
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
            </div>
          </template>
          <template #offer>
            <ul
              class="w-full lg:px-4 px-4 md:px-2 font-normal text-xs lg:text-sm space-y-1"
            >
              <li class="flex items-center justify-between space-x-2">
                <p>
                  2 INSIDER <span v-if="width > breakpoint.sm">MATCHES</span> -
                  <span class="font-bold">50€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="3P6S4PJSTLNXE"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  5 INSIDER <span v-if="width > breakpoint.sm">MATCHES</span> -
                  <span class="font-bold">100€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="XEK9CT34ANZWU"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  10 INSIDER <span v-if="width > breakpoint.sm">MATCHES</span> -
                  <span class="font-bold">170€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="5K7H7ZVGKVL3G"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  20 INSIDER <span v-if="width > breakpoint.sm">MATCHES</span> -
                  <span class="font-bold">270€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="84ZF245HKUBJN"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
            </ul>
          </template>

          <template #header> INSIDER MATCHES </template>
          <template #stake> Stake 9.9/10 </template>
          <template #odds> Odds: Over 2.00 </template>
        </subscription-card>
        <subscription-card class="md:w-1/3">
          <template #star>
            <div class="py-2 flex justify-around">
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
              <base-image
                width="50px"
                height="50px"
                :src="require('../assets/star.png')"
              ></base-image>
            </div>
          </template>
          <template #offer>
            <ul
              class="w-full lg:px-4 px-4 md:px-2 font-normal text-xs lg:text-sm space-y-1"
            >
              <li class="flex items-center justify-between space-x-2">
                <p>
                  3 <span v-if="width > breakpoint.sm">VIP</span> TICKETS -
                  <span class="font-bold">70€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="6T232GNDNW7XL"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  6 <span v-if="width > breakpoint.sm">VIP</span> TICKETS -
                  <span class="font-bold">110€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="C7Z4BNLE2VZW8"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>
                  10 <span v-if="width > breakpoint.sm">VIP</span> TICKETS -
                  <span class="font-bold">170€</span>
                </p>
                <form
                  action="https://www.paypal.com/cgi-bin/webscr"
                  class="flex items-center"
                  method="post"
                  target="_top"
                >
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="DD666EMRZ4AX6"
                  />
                  <input
                    type="image"
                    :src="require('../assets/paypal.png')"
                    width="80"
                    height="17"
                    border="0"
                    name="submit"
                    alt="Buy now"
                  />
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </form>
              </li>
              <li class="flex items-center justify-between space-x-2">
                <p>Contact for more details</p>
              </li>
            </ul>
          </template>

          <template #header> VIP TICKET </template>
          <template #stake> Stake 9/10 </template>
          <template #odds> Odds: Over 3.00 </template>
        </subscription-card>
      </base-card>
    </base-section>

    <base-section class="px-4 py-8">
      <h2 class="text-3xl pb-2 pt-4 px-4">UPCOMING TICKETS</h2>
      <h2 class="text-2xl pt-2 pb-4 px-4">
        Dates:
        <span class="text-active px-2" v-for="(tck, idx) in currentTicketsLive"
          >{{ tck.date }}
        </span>
      </h2>
      <ticket-archive></ticket-archive>
    </base-section>
    <base-section class="px-4 py-8">
      <h2 class="text-3xl pb-2 pt-4 px-4">UPCOMING INSIDERS</h2>
      <h2 class="text-2xl pt-2 pb-4 px-4">
        Dates:
        <span
          class="text-active px-2"
          v-for="(ins, idx) in currentInsidersLive"
        >
          {{ ins.date }}
        </span>
      </h2>

      <insider-proof-archive></insider-proof-archive>
    </base-section>

    <base-section class="px-4 py-8">
      <h2 class="text-3xl py-4 px-4">INSIDER ARCHIVE</h2>
      <base-card class="overflow-hidden rounded-lg bg-white text-slate-500">
        <insider-archive></insider-archive>
      </base-card>
    </base-section>

    <base-section class="px-4 pt-12 pb-4">
      <div class="flex space-x-2 items-center pb-4">
        <!-- <base-banner
          class="w-2/6 flex justify-center"
          :imgSrc="require('../assets/banners/best-tip.gif')"
          :to="'http://www.best-tip1x2.com/'"
          alt="best-tip"
        ></base-banner>
        <base-banner
          class="w-2/6 flex justify-center"
          :imgSrc="require('../assets/banners/best-tip.gif')"
          :to="'http://www.best-tip1x2.com/'"
          alt="best-tip"
        ></base-banner> -->
        <base-banner
          class="w-2/6 flex justify-center"
          :imgSrc="require('../assets/banners/england-picks.gif')"
          :to="'https://www.englandpicks.com/'"
          alt="englandpicks"
        ></base-banner>
      </div>
    </base-section>

    <base-section class="p-4">
      <h2 class="text-3xl py-4 px-4">VIP PICKS ARCHIVE</h2>
      <base-card class="overflow-hidden rounded-lg bg-white text-slate-500">
        <vip-archive></vip-archive>
      </base-card>
    </base-section>

    <base-section class="px-4 pt-12 pb-4">
      <div class="flex items-center flex-wrap md:pb-4">
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/bet-war.gif')"
          :to="'https://www.bet-war.com/'"
          alt="bet-war"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/betconfirm.gif')"
          :to="'http://betconfirm.com/'"
          alt="betconfirm"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/premium-1x2-banner.gif')"
          :to="'https://premium1x2.blogspot.com/'"
          alt="premium1x2"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/picksbet.gif')"
          :to="'http://picks-bet.com/'"
          alt="picks-bet"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/navigator.gif')"
          :to="'http://navigatorbetting.com/'"
          alt="navigator-betting"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/cr7.gif')"
          :to="'http://cr7betting.com/'"
          alt="cr7 betting"
        ></base-banner>
      </div>
      <div class="flex items-center flex-wrap md:pb-4">
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/topsbet.gif')"
          :to="'https://www.topbets1x2.com/'"
          alt="top bets1x2"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/mozzart.gif')"
          :to="'https://mozzart1x2.blogspot.com/'"
          alt="mozzart1x2"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/betmechanic.gif')"
          :to="'http://betmechanic.com/'"
          alt="betmechanic"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/gentlemans.gif')"
          :to="'https://gentlemans1x2.blogspot.com/'"
          alt="gentlemans bet"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/shopbet.gif')"
          :to="'http://shop-bet.com/'"
          alt="shop bet"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/highstake.gif')"
          :to="'https://www.highstakeclub.com/'"
          alt="highstakeclub"
        ></base-banner>
      </div>
      <div class="flex items-center flex-wrap md:pb-4">
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/winning1x2.gif')"
          :to="'https://www.winningteam1x2.com/'"
          alt="winning1x2"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/legendtip.gif')"
          :to="'https://legendtip.beepworld.de/'"
          alt="legendtip"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/improve.gif')"
          :to="'https://www.improvebetting.com/'"
          alt="improvebetting"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/invest.gif')"
          :to="'https://www.investinbetting.com/'"
          alt="investinbetting"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/norway-tips.gif')"
          :to="'https://norway-tips.com/'"
          alt="norway tips"
        ></base-banner>
        <base-banner
          class="w-2/6 pb-1 md:w-1/6 flex justify-center"
          :imgSrc="require('../assets/banners/england-picks.gif')"
          :to="'https://www.englandpicks.com/'"
          alt="englandpicks"
        ></base-banner>
      </div>
    </base-section>

    <base-section class="pt-8 pb-16 px-4">
      <h2 class="text-3xl py-4">FREE PICKS ARCHIVE</h2>
      <base-card class="overflow-hidden rounded-lg bg-white text-slate-500">
        <free-archive></free-archive>
      </base-card>
    </base-section>
  </div>
</template>

<script setup>
import SubscriptionCard from "@/components/SubscriptionCard.vue";
import VipArchive from "@/components/VipArchive.vue";
import TicketArchive from "@/components/TicketArchive.vue";
import InsiderProofArchive from "@/components/InsiderProofArchive.vue";
import FreeArchive from "@/components/FreeArchive.vue";
import InsiderArchive from "@/components/InsiderArchive.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

const vips = computed(() => archiveStore.getVips);

const activeVipDate = computed(() => archiveStore.getCurrentVip.date);
const activeVipTime = computed(() => archiveStore.getCurrentVip.time);

const insiders = computed(() => archiveStore.getInsiders);
const currentInsiders = computed(() => archiveStore.getCurrentInsiders);

const tickets = computed(() => archiveStore.getTickets);
const currentTickets = computed(() => archiveStore.getCurrentTickets);

const breakpoint = computed(() => configStore.getWindowBreakpoint);
const width = computed(() => configStore.getWindowWidth);

const currentDate = computed(() => new Date());

const currentInsidersLive = computed(() => {
  return currentInsiders.value.filter((el) => {
    const d = el.date.split(".");
    const d2 = d[1] + "." + d[0] + "." + d[2];
    const date = new Date(d2);

    if (
      date.getMonth() === currentDate.value.getMonth() &&
      date.getDay() === currentDate.value.getDay() &&
      date.getFullYear() === currentDate.value.getFullYear()
    ) {
      return true;
    } else return date >= currentDate.value;
  });
});

const currentTicketsLive = computed(() => {
  return currentTickets.value.filter((el) => {
    const d = el.date.split(".");
    const d2 = d[1] + "." + d[0] + "." + d[2];
    const date = new Date(d2);

    if (
      date.getMonth() === currentDate.value.getMonth() &&
      date.getDay() === currentDate.value.getDay() &&
      date.getFullYear() === currentDate.value.getFullYear()
    ) {
      return true;
    } else return date >= currentDate.value;
  });
});

console.log(currentInsidersLive.value);
</script>

<style>
.hero {
  background-image: url("../assets/hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: all ease-in-out 0.1s;
}
</style>
