<script setup>
import InsiderItem from "@/components/InsiderItem.vue";
import { useArchiveStore } from "@/stores/archive";
import { useConfigStore } from "@/stores/config";
import { computed, ref } from "vue";
import ins from "@/assets/data/insiders.json";

const archiveStore = useArchiveStore();
const configStore = useConfigStore();

// const insiders = computed(()=> archiveStore.getInsiders)
const insiders = computed(() => ins.reverse());

const currentPage = ref(1);
const rowsPerPage = ref(20);

const startIndex = computed(() => (currentPage.value - 1) * rowsPerPage.value);
const endIndex = computed(() => startIndex.value + rowsPerPage.value);

const slicedInsiders = computed(() =>
  ins.slice(startIndex.value, endIndex.value)
);
const pageCount = computed(() =>
  Math.ceil(insiders.value.length / rowsPerPage.value)
);

const currentInsiders = computed(() => archiveStore.getCurrentInsiders);

const currentDate = computed(() => new Date());

const currentInsidersLive = computed(() => {
  return currentInsiders.value.filter((el) => {
    const d = el.date.split(".");
    const d2 = d[1] + "." + d[0] + "." + d[2];
    const date = new Date(d2);

    if (
      date.getMonth() === currentDate.value.getMonth() &&
      date.getDay() === currentDate.value.getDay() &&
      date.getFullYear() === currentDate.value.getFullYear()
    ) {
      return true;
    } else return date >= currentDate.value;
  });
});

const handlePagination = (page) => {
  currentPage.value = page;
};
</script>

<template>
  <ul :key="insiders">
    <li
      v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      class="flex items-center justify-center font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue bg-darkblue text-white py-1"
    >
      <h3 class="w-2/12 flex items-center justify-center p-2">DATE</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">TIME</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">LEAGUE</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">HOME</h3>
      <h3 class="w-2/12 flex items-center justify-center p-2">AWAY</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">PICK</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">ODD</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">RES.</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">PROOF</h3>
      <h3 class="w-1/12 flex items-center justify-center p-2">FT</h3>
    </li>

    <li v-for="(ins, idx) in currentInsidersLive" :key="idx">
      <div
        v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
        class="flex items-center justify-center font-bold text-xs md:text-sm lg:text-base border-b-[1px] border-darkblue/70 bg-darkblue text-active py-1"
      >
        <h3 class="w-2/12 flex items-center justify-center p-2 text-center">
          {{ ins.date }}
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          <base-spinner></base-spinner>
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          INSIDER MATCH
        </h3>
        <h3 class="w-2/12 flex items-center justify-center p-2 text-center">
          CONFIRMED
        </h3>
        <h3 class="w-2/12 flex items-center justify-center p-2 text-center">
          HIGHLY ANALYSED
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          <base-spinner></base-spinner>
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          OVER 2.00
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          <base-spinner></base-spinner>
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          BUY NOW
        </h3>
        <h3 class="w-1/12 flex items-center justify-center p-2 text-center">
          <base-spinner></base-spinner>
        </h3>
      </div>

      <div
        v-else
        class="flex items-center text-xs md:text-sm lg:text-base py-1 px-2 space-x-2 border-b-[1px] border-darkblue/70 font-bold bg-darkblue text-active"
      >
        <div class="w-4/12 space-y-[0.125rem]">
          <div class="flex space-x-1">
            <span>{{
              configStore.getWindowWidth > 360 ? ins.date : ins.date.slice(0, 5)
            }}</span>
          </div>
          <div class="flex">INSIDER MATCH</div>
        </div>
        <div class="w-4/12 space-y-[0.125rem]">
          <div class="flex items-center space-x-2"><span>CONFIRMED</span></div>
          <div class="flex items-center space-x-2"><span>OVER 2.00</span></div>
        </div>
        <div class="w-3/12 flex items-center justify-center">
          <div>BUY NOW</div>
        </div>
        <div class="w-1/12 flex items-center justify-center">
          <base-spinner></base-spinner>
        </div>
      </div>
    </li>

    <insider-item
      v-for="(ins, idx) in slicedInsiders"
      :key="idx"
      :data="ins"
      :index="idx + 1"
    ></insider-item>
    <li
      class="flex p-2 min-h-[43px] justify-center items-center font-bold text-sm md:text-base lg:text-lg border-b-[1px] border-darkblue/90 bg-darkblue py-1"
    >
      <!-- <h3 class="w-full flex items-center justify-end p-2 text-sky-300">
        &nbsp;v-if="configStore.getWindowWidth > configStore.getWindowBreakpoint.md"
      </h3> -->
      <div v-if="pageCount > 1" class="flex justify-center items-center p-2">
        <button
          v-if="currentPage > 1"
          class="px-1"
          @click="handlePagination(currentPage - 1)"
        >
          <img class="h-4" :src="require('../assets/left-chevron.png')" />
        </button>
        <button
          v-for="index in pageCount"
          :key="index"
          :class="{ 'text-active': currentPage === index }"
          class="px-1"
          @click="handlePagination(index)"
        >
          {{ index }}
        </button>
        <button class="px-1" @click="handlePagination(currentPage + 1)">
          <img
            v-if="currentPage < pageCount"
            class="h-4 rotate-180"
            :src="require('../assets/left-chevron.png')"
          />
        </button>
      </div>
    </li>
  </ul>
</template>
